import { Link, graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'

import Img from 'gatsby-image'
import NavbarLinks from '../NavbarLinks'
import { Wrapper } from './styles'

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query LogoImageQuery {
      file(relativePath: { eq: "images/logo-kevinlabory.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [isSticky, setSticky] = useState(false)
  const handleScroll = () => {
    const scroll = window.scrollY
    setSticky(scroll >= 50)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  return (
    <Wrapper className={isSticky ? 'sticky-nav' : ''}>
      <div className="header-logo">
        <Link to="/">
          <svg
            version="1.0"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 356.5 50.2"
            enableBackground="new 0 0 356.5 50.2"
            xmlSpace="preserve"
          >
            <g>
              <g className="mobile-invisible">
                <polygon
                  fill="#989DA5"
                  points="210.3,12.9 206.5,12.9 206.5,37.3 222.5,37.3 222.5,33.7 210.3,33.7"
                />
                <path
                  fill="#989DA5"
                  d="M270.9,24.3v-0.1c1.6-0.5,3.6-2.1,3.6-5.1c0-3.4-2.9-6.1-7.4-6.1h-9.3v24.4h9.8c5.6,0,8.5-2.9,8.5-6.9
                  C276,26.6,273.2,24.8,270.9,24.3z M261.6,16.1h4.8c2.6,0,4.2,1.4,4.2,3.5c0,2.2-1.7,3.5-4,3.5h-5V16.1z M267.3,34.1h-5.7V26h5.6
                  c2.6,0,4.7,1.5,4.7,4.1C272,32.7,270.2,34.1,267.3,34.1z"
                />
                <path
                  fill="#989DA5"
                  d="M293.8,12.4c-7.2,0-12.7,5.1-12.7,12.7c0,7.5,5.5,12.7,12.7,12.7c7.2,0,12.8-5.1,12.8-12.7
                  C306.6,17.5,301.1,12.4,293.8,12.4z M293.8,34.1c-5,0-8.8-3.5-8.8-9c0-5.6,3.8-9.1,8.8-9.1c5,0,8.9,3.5,8.9,9.1
                  C302.7,30.7,298.8,34.1,293.8,34.1z"
                />
                <path
                  fill="#989DA5"
                  d="M330.4,20.7c0-4.8-3.5-7.8-9.3-7.8H313v24.4h3.8V16.4h4.4c3.3,0,5.2,1.5,5.2,4.5c0,3-2,4.2-5.1,4.2h-2
                  l7.1,12.2h4.6l-5.9-9.8C328.4,26.6,330.4,24.4,330.4,20.7z"
                />
                <path
                  fill="#989DA5"
                  d="M352.3,12.9l-5,7.6c-1.1,1.7-1.9,3-2,3h-0.1c0,0-0.9-1.4-2-3.1l-4.9-7.6l-4.5,0l9.3,14.1v10.3h3.9V27
                  l9.3-14.1H352.3z"
                />
                <path
                  fill="#989DA5"
                  d="M237.5,12.9l-10.1,24.4h4l1.8-4.5h12.6l1.8,4.5h4.1l-10-24.4H237.5z M234.7,29.2l4.8-11.9h0.1l4.8,11.9
                  H234.7z"
                />
              </g>
              <g className="mobile-visible">
                <polygon
                  fill="#989DA5"
                  points="50.1,0 50.1,0 9.9,0 9.9,5.5 44.7,5.5 44.7,50.2 50.1,50.2 50.2,0.3"
                />
                <polygon points="5.5,0 0,0 0,44.7 0,44.7 0,49.9 0,50.1 0,50.2 5.2,50.2 5.5,50.1 40.2,50.1 40.2,44.7 5.5,44.7" />
                <rect x="13.2" y="13.2" width="5.6" height="23.8" />
                <polygon points="30.8,13.2 20.5,25.1 30.8,37 38.2,37 27.9,25.1 38.2,13.2" />
              </g>
              <g className="mobile-invisible">
                <path d="M149.2,24.6c-1.5,4.5-2.1,6.3-2.1,6.4H147c0-0.1-0.6-2-2.1-6.6l-3.8-11.6h-6.7l8.7,24.4h7.5l8.7-24.4H153L149.2,24.6z" />
                <rect x="164.5" y="12.9" width="5.9" height="24.4" />
                <path d="M193.4,12.9v8.5c0,2.6,0,5.8,0,5.8h-0.1L183,12.9H177v24.4h5.9v-8.4c0-3.2-0.1-6.2-0.1-6.2h0.1l10.4,14.6h6V12.9H193.4z" />
                <rect x="79.7" y="12.9" width="5.7" height="24.4" />
                <polygon points="97.7,12.9 87.1,25.1 97.7,37.2 105.2,37.2 94.7,25.1 105.2,12.9" />
                <polygon
                  points="110,37.3 129.4,37.3 129.4,32.6 115.9,32.6 115.9,27.4 129.4,27.4 129.4,22.8 115.9,22.8 115.9,17.5 129.4,17.5
                  129.4,12.9 110,12.9"
                />
              </g>
            </g>
            <foreignObject width="0" height="0" overflow="hidden">
              <Img fluid={data.file.childImageSharp.fluid} />
            </foreignObject>
          </svg>
        </Link>
      </div>

      <NavbarLinks desktop />
    </Wrapper>
  )
}

export default Navbar
