module.exports = {
  crispWebsiteId: '724147f6-b636-4a6e-920f-f3109ba71cef',
  logo: 'https://www.kevinlabory.com/favicon/favicon-209.png',
  author: 'Kevin Labory',
  url: 'https://www.kevinlabory.com',
  legalName: 'Kevin Labory',
  socialLinks: {
    twitter: 'http://www.twitter.com/kevinlabory',
    github: 'https://github.com/kevinlabory',
    linkedin: 'https://www.linkedin.com/in/kevinlabory',
  },
  googleAnalyticsID: 'UA-69352974-2',
  social: {
    twitter: '@kevinlabory',
  },
  address: {
    city: 'Brest',
    region: 'Bretagne',
    country: 'France',
    zipCode: '29200',
  },
  contact: {
    email: 'kevinlabory@protonmail.com',
    phone: '+33689994981',
  },
  foundingDate: '2020',
}
