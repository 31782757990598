import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #242423;
  padding: 28rem 0 4rem 0;

  @media (max-width: 1960px) {
    padding: 4rem 0 4rem;
  }
`

export const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 680px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`

export const Links = styled.div`
  align-items: center;
  display: flex;

  a {
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(0, 0, 0, 0) !important;
    color: rgba(84, 91, 98, 1) !important;
    display: block;
    font-size: 30px;
    height: 60px;
    line-height: 60px;
    margin-right: 1rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.4s ease-in-out;
    width: 60px;
    &:after,
    &:before {
      opacity: 0;
      transition: all 0.4s ease-in-out;
      border: 1px solid rgba(255, 255, 255, 0.1);
      content: '';
      height: 20px;
      position: absolute;
      width: 20px;
    }
    &:after {
      border-bottom: 0;
      border-left: 0;
      right: 0;
      top: 0;
    }
    &:before {
      border-right: 0;
      border-top: 0;
      bottom: 0;
      left: 0;
    }
    &:after {
      opacity: 1;
      right: -5px;
      top: -5px;
    }
    &:before {
      bottom: -5px;
      left: -5px;
      opacity: 1;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0);
      color: rgba(84, 91, 98, 1) !important;
      &:after {
        opacity: 1;
        right: -1px;
        top: -1px;
      }
      &:before {
        bottom: -1px;
        left: -1px;
        opacity: 1;
      }
    }
  }
`

export const Details = styled.div`
  color: #989da5;

  @media (max-width: 680px) {
    margin-bottom: 2rem;
  }
`
