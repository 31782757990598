import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  background-color: #fff !important;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 0 1rem;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out;
  width: 100%;
  z-index: 1;

  a {
    color: #000;
  }

  .header-logo {
    line-height: 81px;
    width: 230px;
  }

  @media (min-width: 601px) {
    padding: 0 1rem 0 1rem;
  }

  @media (min-width: 1180px) {
    padding: 0 8rem 0 8rem;
  }
`
