import { Details, Flex, Wrapper } from './styles'

import { Container } from '..'
import React from 'react'

export const NotFound = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <h1>404</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Details>
    </Flex>
  </Wrapper>
)
