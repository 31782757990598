import './fonts.css'

import { Footer } from '../../theme'
import { Global } from './styles'
import React from 'react'

export const Layout = ({ children }) => (
  <>
    <Global />
    {children}
    <Footer />
  </>
)
