import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  display: flex;

  a {
    border-bottom: 3px solid transparent;
    color: #6d6d6d !important;
    display: block;
    font-size: 0.9rem;
    line-height: 78px;
    padding: 0 20px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:active {
      border-color: #545b62;
      color: #000;
      text-decoration: none;
    }
  }

  .languages-switcher {
    padding: 0;
    display: inline-block;
  }

  .header-menu-item {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
  }

  ${({ desktop }) =>
    desktop
      ? `
      @media (max-width: 960px) {
          display: none;
      }
    `
      : `
      padding: 3rem;
      display: flex;
      flex-direction: column;

      a {
          color: #6d6d6d !important;
          margin-bottom: 1rem;

          &:last-child {
              margin-bottom: unset;
          }
      }
  `}
`
