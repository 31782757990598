import { createGlobalStyle } from 'styled-components'

export const Global = createGlobalStyle`
  body {
    font-family: 'Montserrat', Helvetica, sans-serif;
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Montserrat', Helvetica, sans-serif;
  }

  a,
  a:hover,
  a:focus,
  button,
  button:focus {
    text-decoration: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  ::selection {
    background: rgba(166, 175, 189, 0.3);
  }

  ::-moz-selection {
    background: rgba(166, 175, 189, 0.3);
  }

  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 9999999;
  }

  #status {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }

  .spinner {
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid rgba(0, 0, 0, 0.2);
    border-right: 5px solid rgba(0, 0, 0, 0.2);
    border-bottom: 5px solid rgba(0, 0, 0, 0.2);
    border-left: 5px solid #000;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }

  .spinner,
  .spinner:after {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  @-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }

  .sticky-nav {
    box-shadow: 0 1px 8px 3px rgba(0, 0, 0, 0.05);

    a {
      color: #000;
    }
  }

  .back_top {
    text-align: center;
    position: fixed;
    bottom: 15px;
    height: 35px;
    width: 35px;
    display: none;
    z-index: 10000;
    border-radius: 3px;
    right: 20px;
    transition: all 0.5s;
  }

  .back_top i {
    color: #fff;
    line-height: 35px;
    font-size: 32px;
    display: block;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    background-color: #fff;
  }

  .h-100vh {
      height: 100vh;
  }

  .z-index {
      z-index: 2;
  }

  .clippath_none {
      clip-path: none !important;
  }

  .section-subtitle {
      max-width: 500px;
  }

  .btn {
      padding: 15px 32px;
      font-size: 14px;
      font-weight: 700;
      transition: all 0.5s;
      letter-spacing: 0.6px;
      color: #fff;
      box-shadow: none !important;
      text-transform: uppercase;
      outline: none !important;
  }

  .btn-round {
      border-radius: 30px;
  }

  .btn-custom{
      border:1px solid;
  }

  .btn-custom:hover,
  .btn-custom:focus,
  .btn-custom:active,
  .btn-custom.active,
  .btn-custom.focus,
  .btn-custom:active,
  .btn-custom:focus,
  .btn-custom:hover,
  .open > .dropdown-toggle.btn-custom {
      color: #f3f3f3;
  }

  .btn-outline-custom {
      border: 2px solid #f5f5f5;
  }

  .btn-outline-custom:hover,
  .btn-outline-custom:focus,
  .btn-outline-custom:active,
  .btn-outline-custom.active,
  .btn-outline-custom.focus,
  .btn-outline-custom:active,
  .btn-outline-custom:focus,
  .btn-outline-custom:hover,
  .open > .dropdown-toggle.btn-outline-custom {
      color: #000;
      background-color: #fff;
      border-color: #fff;
  }

  .bg-overlay {
      background-color: rgba(0, 0, 0, 0.69);
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
  }

  .text-custom,
  .custom-nav .navbar-nav li a:hover,
  .custom-nav .navbar-nav li a:active,
  .custom-nav .navbar-nav li.active a,
  .custom-nav.stickyadd .navbar-nav li a:hover,
  .custom-nav.stickyadd .navbar-nav li a:active,
  .custom-nav.stickyadd .navbar-nav li.active a,
  .about-social li a:hover {
      color: #989DA5 !important;
  }

  .btn-custom,
  .form-kerri .form-control:focus,
  .about-social li a:hover {
      border-color: #989DA5;
  }

  .btn-custom,
  .back_top,
  .services-boxes .services-title-border,
  .owl-theme .owl-controls .owl-page span,
  .work_menu a.active,
  .work_menu a:hover {
      background-color: #989DA5;
  }

  .btn-custom:hover,
  .btn-custom:focus,
  .btn-custom:active,
  .btn-custom.active,
  .btn-custom.focus,
  .btn-custom:active,
  .btn-custom:focus,
  .btn-custom:hover,
  .open > .dropdown-toggle.btn-custom {
      background-color: #31a3c6;
      border-color: #31a3c6;
  }
`
