import React, { Fragment } from 'react'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'

import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Wrapper } from './styles'

const NavbarLinks = ({ desktop }) => {
  const { t } = useTranslation()
  const { languages, changeLanguage } = useI18next()
  return (
    <Wrapper desktop={desktop}>
      <AnchorLink offset="72" href="#about">
        <span className="header-menu-item">{t('About')}</span>
      </AnchorLink>
      <AnchorLink offset="72" href="#services">
        <span className="header-menu-item">Services</span>
      </AnchorLink>
      <AnchorLink offset="72" href="#projects">
        <span className="header-menu-item">{t('Works')}</span>
      </AnchorLink>
      <AnchorLink offset="72" href="#contact">
        <span className="header-menu-item">Contact</span>
      </AnchorLink>
      <div className="header-menu-item">
        {languages.map((lng, idx) => (
          <Fragment key= {idx}>
            <a
              className="languages-switcher"
              href="/"
              onClick={(e) => {
                e.preventDefault()
                changeLanguage(lng)
              }}
            >
              {lng}
            </a>
            <span>{idx === 0 ? ' / ' : ''}</span>
          </Fragment>
        ))}
      </div>
    </Wrapper>
  )
}

export default NavbarLinks
