import React, { useState } from 'react'
import {
  address,
  contact,
  foundingDate,
  legalName,
  logo,
  social,
  socialLinks,
  url,
} from '../../../data/config'

import Helmet from 'react-helmet'
import Thumbnail from '../../../assets/thumbnail/thumbnail.png'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const SEO = ({ location = '' }) => {
  const { t } = useTranslation()
  const [title] = useState(t('Kevin Labory | Technical advisor, DevOps & APIs'))
  const [description] = useState(t('Technical advisor, DevOps & APIs'))

  const structuredDataOrganization = `{ 
    "@context": "http://schema.org",
    "@type": "Organization",
    "legalName": "${legalName}",
    "url": "${url}",
    "logo": "${logo}",
    "foundingDate": "${foundingDate}",
    "founders": [{
      "@type": "Person",
      "name": "${legalName}"
    }],
    "contactPoint": [{
      "@type": "ContactPoint",
      "email": "${contact.email}",
      "telephone": "${contact.phone}",
      "contactType": "customer service"
    }],
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "${address.city}",
      "addressRegion": "${address.region}",
      "addressCountry": "${address.country}",
      "postalCode": "${address.zipCode}"
    },
    "sameAs": [
      "${socialLinks.twitter}",
      "${socialLinks.linkedin}",
      "${socialLinks.github}"
    ]
  }`

  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta name="image" content={Thumbnail} />

      <meta
        property="og:url"
        content={`${url}${location}/?ref=www.kevinlabory.com`}
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={Thumbnail} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={socialLinks.twitter} />
      <meta name="twitter:site" content={social.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image:src" content={Thumbnail} />
      <script type="application/ld+json">{structuredDataOrganization}</script>
      <title>{title}</title>
      <html lang="en" dir="ltr" />
    </Helmet>
  )
}
