import { Details, Flex, Links, Wrapper } from './styles'

import { Container } from '../../common'
import React from 'react'
import social from './social.json'

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <span>
          © Kevin Labory | {new Date().getFullYear()} | Made with{' '}
          <span aria-label="love" role="img">
            ❤️
          </span>{' '}
          in Brest{' '}
          <span aria-label="telecabine" role="img">
            🚠
          </span>
        </span>
      </Details>
      <Links>
        {social.map(({ id, name, link, slug }) => (
          <a
            key={id}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`follow me on ${name}`}
          >
            <i className={`mdi mdi-${slug}`}></i>
          </a>
        ))}
      </Links>
    </Flex>
  </Wrapper>
)
