import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #f8f9fa;
  height: 100vh;
  padding: 28rem 0 4rem 0;

  @media (max-width: 1960px) {
    padding: 4rem 0 4rem;
  }
`

export const Flex = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
`

export const Details = styled.div`
  color: #989da5;

  @media (max-width: 680px) {
    margin-bottom: 2rem;
  }
`
